<template>
     <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" class="lg:mx-40 mx-7 rounded-2xl bg-blueNew1 lg:my-20 my-14 mt-10 lg:pb-4 pb-10">
     <div class="lg:w-auto bg-blueNew1 text-white lg:flex lg:justify-center lg:items-stretch lg:mt-0 w-80 min-h-[33rem] mt-12 mb-12 shadow-[0_2.5rem_2rem_#00000030] relative  rounded-3xl">
        <div class="lg:w-2/3 relative">
            <div class="lg:overflow-hidden lg:relative lg:flex lg:items-center h-full">
                <picture>
                    <source
                        srcset="../../assets/images/cover.jpg"
                        media=""
                    >
                    <img class="lg:-left-[4.25rem] lg:top-0 lg:relative lg:z-10 lg:w-auto lg:max-w-full lg:drop-shadow-none w-60 absolute -top-[6.75rem] left-11 mx-auto drop-shadow-[0_2rem_0_#aaaaff30]" src="../../assets/images/illustration-woman-online-mobile.svg" alt="illustration">
                    
                </picture>
                
                <img class="hidden lg:block absolute -left-[17.9rem] top-2 scale-[2]" src="../../assets/images/cover.jpg" alt="background pattern">
            </div>
        </div>

    
    </div>
  </div> 
</template>

<script>

export default {
  name: "index",

  components: {
    

    },
}

</script>
